<template>
  <img :src="src" alt="Banner Image" />
  <div class="top-left-text">
    <h1 :style="{ color: branding.banner_title_color }">{{ title }}</h1>
    <h3>{{ subtitle }}</h3>
    <p>
      {{ description }}
    </p>
    <ul v-if="dashboardList">
      <li>Get instant access to your information on desktop or mobile</li>
      <li>Raise a request quickly and conveniently</li>
      <li>See what you're covered for with just one tap</li>
      <li>Access quality support to help you every step of the way</li>
    </ul>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useBrandingStore } from '../store/branding';

defineProps({
  src: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    required: false,
  },
  description: {
    type: String,
    required: true,
  },
  dashboardList: {
    type: Boolean,
    required: false,
  },
});

const brandingStore = useBrandingStore();
const branding = brandingStore.brandCSS;
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}

.top-left-text {
  position: absolute;
  color: #fff;

  h1 {
    color: #ef7e31;
  }

  h3 {
    font-weight: unset;
  }

  p {
    color: #fff;
  }

  ul {
    padding-left: 18px;
  }
}

@media only screen and (max-width: 768px) {
  .top-left-text {
    top: 50px;
    left: 34px;
    width: 75%;

    h1 {
      font-size: 36px;
    }

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 14px;
      width: 90%;
    }
  }
}

@media only screen and (max-width: 320px) {
  .top-left-text {
    top: 22px;
    left: 34px;
    width: 70%;

    h1 {
      font-size: 24px;
    }

    h3 {
      font-size: 12px;
    }

    p {
      font-size: 10px;
      width: 90%;
    }
  }
}

@media only screen and (min-width: 601px) {
  .top-left-text {
    top: 30px;
    left: 75px;
    width: 52%;

    h1 {
      font-size: 42px;
    }

    p {
      width: 60%;
    }
  }
}
</style>
